// 提交蝶双飞登记内容
const saveOrUpdateDsfRegisterInfoUrl = `/gateway/hc-serve/dsf/saveOrUpdateDsfRegisterInfo`;
//牵线进度-审核
const updateStatusUrl = `/gateway/hc-serve/dsf/updateStatus`;
//查询蝶双飞信息列表
const getDsfRegisterInfoListUrl = `/gateway/hc-serve/dsf/getDsfRegisterInfoList`;
//保存或更新蝶双飞详情内容
const saveOrUpdateDsfContentInfoUrl = `/gateway/hc-serve/dsf/saveOrUpdateDsfContentInfo`;
//撤回提交
const withdrawalUrl = `/gateway/hc-serve/dsf/withdrawal`;
//根据用户ID获取蝶双飞登记信息
const getDsfRegisterInfoByUserIdUrl = `/gateway/hc-serve/dsf/getDsfRegisterInfoByUserId`;
//查询蝶双飞信息列表
const getDsfContentInfoListUrl = `/gateway/hc-serve/dsf/getDsfContentInfoList`;
//获取权限
const getFunctionSettingUrl = `/gateway/blade-system/application/getFunctionSetting`;
//用户信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
export {
  userInfoUrl,
  getFunctionSettingUrl,
  saveOrUpdateDsfRegisterInfoUrl,
  updateStatusUrl,
  getDsfRegisterInfoListUrl,
  saveOrUpdateDsfContentInfoUrl,
  withdrawalUrl,
  getDsfRegisterInfoByUserIdUrl,
  getDsfContentInfoListUrl,
};
